import React, { Component, Fragment } from 'react'
import { gql } from "apollo-boost"
import Navbar from '../components/Navbar'

import chevronIcon from '../assets/iconic/chevron.svg'
import Container from '../views/Container'
import Context from '../models/Context'
import csaLogo from '../assets/csa-logo24.gif'
import { ReactComponent as CrossIcon } from "../assets/cross.svg"

class MyScreen extends Component {
  constructor (props) {
    super(props)

    this.renderNavbarHeaderLeft = this.renderNavbarHeaderLeft.bind(this)
    this.renderNavbarHeaderRight = this.renderNavbarHeaderRight.bind(this)
    this.onBack = this.onBack.bind(this)

    this.state = {
        contentMy: '',
        participatesInContest: true,
        contentContest: '',
        username: '',
        profile: null,
        newsletterSubscribeSuccessMessage: null,
        newsletterSubscribeErrorMessage: null,
    }

    this.onPasswordChangeClicked = this.onPasswordChangeClicked.bind(this)
    this.onNewsletterSubscribeClicked = this.onNewsletterSubscribeClicked.bind(this)
    this.onLogout = this.onLogout.bind(this)
    this.onEditProfileClicked = this.onEditProfileClicked.bind(this)

    this.newsletterFormHTML = `
    <!-- Begin Mailchimp Signup Form -->
    <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
    <style type="text/css">
      #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:300px; color: #707070; padding-top: .25em; }
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
         We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
        <form action="https://wettermelden.us11.list-manage.com/subscribe/post?u=6488f39dc6435e28b5978a997&amp;id=5b64d39c23&amp;f_id=0029a3e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
            <h2>Subscribe</h2>
            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
    <div class="mc-field-group">
      <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
    </label>
      <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
      <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
    </div>
      <div id="mce-responses" class="clear foot">
        <div class="response" id="mce-error-response" style="display:none"></div>
        <div class="response" id="mce-success-response" style="display:none"></div>
      </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_6488f39dc6435e28b5978a997_5b64d39c23" tabindex="-1" value=""></div>
            <div class="optionalParent">
                <div class="clear foot">
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
                    <p class="brandingLogo"><a href="http://eepurl.com/imbM-Y" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p>
                </div>
            </div>
        </div>
    </form>
    </div>
    <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
    <!--End mc_embed_signup-->
    `;
  }

  componentDidMount () {
    this.fetchContent()

    const { authService, getUser } = this.context

    const user = getUser()
    const { username } = user
    this.setState({ username })

    authService.fetchProfile().then(response => {
      const { profile } = response
      this.setState({
        profile,
        participatesInContest: profile.contest
      })
      const emailInput = Array.from(document.getElementsByName('EMAIL'))[0];
      if (emailInput) {
        emailInput.value = profile.gravatar_email;
      }
    })
  }

  fetchContent () {
    const { graphQLClient } = this.context
    const site = 'trustedSpotterAustriaDe'
    const promise = graphQLClient
      .query({
        query: gql`
          query MyWettermelden {
            entries (site:["${site}"], section: "myWettermelden") {
                ...on myWettermelden_myWettermelden_Entry {
                    my: richText
                    contest: richText2
                }
            }
          }
        `
      })

    promise.then(response => {
      const content = response.data.entries[0]

      this.setState({
        contentMy: content.my,
        contentContest: content.contest
      })
    }).catch((error) => { })
  }

  render () {
    const { t } = this.context
    const title = t('SCREENS.MY.TITLE')
    return (
        <div className="my-screen">
            <div>
                <Navbar
                title={title}
                className="app__navbar"
                renderHeaderLeft={this.renderNavbarHeaderLeft}
                renderHeaderRight={this.renderNavbarHeaderRight}
                />
            </div>
            <Container>
                <div className="container mx-auto px-4 pt-28 md:pt-32 pb-12">
                    <div className="prose">
                        <div dangerouslySetInnerHTML={{__html: this.state.contentMy}} />
                    </div>

                    { /* this.renderContest() */ }

                    { this.renderProfile() }
                </div>
            </Container>
        </div>
    )
  }

  renderContest () {
    const { t } = this.context
    if (this.state.participatesInContest) {
        return (
            <div className="section">
                <img src={csaLogo} alt="Citizen Science Award" />
                <div dangerouslySetInnerHTML={{__html: this.state.contentContest}} />
                <div style={{ marginTop: '1em'}}>
                  <p>
                    <button className="btn-primary" onClick={this.onEditProfileClicked}>{t('SCREENS.MY.EDIT_CONTEST')}</button>
                  </p>
                </div>
            </div>
        )
    } else {
        return (
          <div className="section">
            <img src={csaLogo} alt="Citizen Science Award" />
            <div style={{ marginTop: '1em'}}>
              <p>
                <button className="btn-primary" onClick={this.onEditProfileClicked}>{t('SCREENS.MY.REGISTER_CONTEST')}</button>
              </p>
            </div>
          </div>
        )
    }
  }

  renderProfile () {
    const { t } = this.context
    return (
        <div className="section">
            <h2 className="text-xl sm:text-3xl text-gray font-bold my-4">{ t('SCREENS.MY.TITLE_PROFILE') }</h2>
            <p className="my-4">
              <strong>{ t('HELPERS.USERNAME') }</strong>: {this.state.username}
            </p>
            <p className="my-6">{ this.state.profile ? this.renderUserType(this.state.profile.user_type) : ''}</p>
            <p>
              <button className="btn-primary" onClick={this.onEditProfileClicked}>{t('SCREENS.MY.EDIT_PROFILE')}</button>
            </p>
            <p className="my-6">
              <button className="btn-primary" onClick={this.onPasswordChangeClicked}>{t('SCREENS.MY.CHANGE_PASSWORD')}</button>
            </p>
            <div className="my-6"  dangerouslySetInnerHTML={{__html: this.newsletterFormHTML}}></div>

            <p className="my-4">
              <button onClick={this.onLogout} className="btn-secondary h-12 mt-8" type="button">{t('SCREENS.MY.LOGOUT')}</button>
            </p>
        </div>
    )
  }

  renderNavbarHeaderLeft () {
    return (
      <Fragment>

          <button onClick={this.onBack}>
            <img data-src={chevronIcon} className="iconic w-8 h-8" data-direction="left" alt="back" />
          </button>

      </Fragment>
    )
  }

  renderNavbarHeaderRight() {
    const { t } = this.context

    return (
      <Fragment>
        {/* <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-8 md:my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full"> */}
        <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full">
          {/* <CrossIcon className="fill-current text-white" /><span className="md:hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span> */}
          <CrossIcon className="fill-current text-white" /><span className="hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span>
        </button>
      </Fragment>
    )
  }

  renderUserType(type) {
    const { t } = this.context

    let typeString
    switch(type) {
      case 'trusted':
        typeString = t('HELPERS.USER_TYPE_TRUSTED_SPOTTER')
        break;
      case 'student':
        typeString = t('HELPERS.USER_TYPE_STUDENT')
        break;
      default:
        typeString = t('HELPERS.USER_TYPE_PRIVATE')
    }

    return (
      <span><strong>{ t('HELPERS.USER_TYPE') }</strong>: {typeString}</span>
    )
  }

  onLogout () {
    const { authService, setUser } = this.context
    authService.logout()
    setUser({ username: null })
    const { history } = this.props
    history.push('/reports/')
  }

  onBack () {
    const { history } = this.props
    history.push('/reports/')
  }

  onPasswordChangeClicked () {
    const { history } = this.props
    history.push('/change-password/')
  }

  async onNewsletterSubscribeClicked () {
    const { subscribeToNewsletter, t } = this.context

    this.setState({
      newsletterSubscribeSuccessMessage: null,
      newsletterSubscribeErrorMessage: null
    })

    try {
      await subscribeToNewsletter(this.state.profile.gravatar_email)

      this.setState({
        newsletterSubscribeSuccessMessage: t('SCREENS.MY.NEWSLETTER_SUBSCRIBE_SUCCESS')
      })
    } catch(e) {
      this.setState({
        newsletterSubscribeErrorMessage: t('SCREENS.MY.NEWSLETTER_SUBSCRIBE_ERROR')
      })
    }
  }

  onEditProfileClicked () {
    const { history } = this.props
    history.push('/edit-profile/')
  }
}

MyScreen.contextType = Context

export default MyScreen
