import React, { Component } from 'react'
import { withTimeout, TimeoutError, isOnline } from '../lib/utils/network'
import config from '../config/config'
import { errorMessagesFromApiErrors } from '../lib/utils/string'
import csaLogo from '../assets/csa-logo24.gif'

class EditProfileView extends Component {
  constructor (props) {
    super(props)

    this.profile = {
      user_type: 'private',
      salutation: 'Frau'
    }

    this.state = {
      userType: 'private',
      saveButtonEnabled: true,
      saving: false,
      successMessageForm: '',
      errorMessageForm: '',
      errorMessageContest: '',
      errorMessageUser_type: '',
      errorMessageSalutation: '',
      errorMessageFirst_name: '',
      errorMessageLast_name: '',
      errorMessageStreetAddress: '',
      errorMessageZip: '',
      errorMessageCity: '',
      errorMessagePhone: '',
      errorMessageTrusted_spotter_number: '',
      errorMessageSchool_name: '',
      errorMessageClass_name: '',
      errorMessageContact_email: '',
    }

    this.onSave = this.onSave.bind(this)
    this.onUserTypeChange = this.onUserTypeChange.bind(this)
    this.onSalutationChange = this.onSalutationChange.bind(this)
    this.onFirstNameChange = this.onFirstNameChange.bind(this)
    this.onLastNameChange = this.onLastNameChange.bind(this)
    this.onStreetAddressChange = this.onStreetAddressChange.bind(this)
    this.onZipChange = this.onZipChange.bind(this)
    this.onCityChange = this.onCityChange.bind(this)
    this.onPhoneChange = this.onPhoneChange.bind(this)
    this.onTrustedSpotterNumberChange = this.onTrustedSpotterNumberChange.bind(this)
    this.onSchoolNameChange = this.onSchoolNameChange.bind(this)
    this.onClassNameChange = this.onClassNameChange.bind(this)
    this.onContactEmailChange = this.onContactEmailChange.bind(this)
    this.onContestChange = this.onContestChange.bind(this)
  }

  onTextChange (key, text) {
    this.profile[key] = text
  }

  onUserTypeChange () {
    const value = this.userTypeSelect.value

    this.onTextChange('user_type', value)



    if (value !== 'trusted') {
        this.profile.trusted_spotter_number = ''
    }

    this.setState({ userType: value }, () => {
      this.setInputs()
    })

  }

  onSalutationChange () {
    this.onTextChange('salutation', this.salutationSelect.value)
  }

  onFirstNameChange () {
    this.onTextChange('first_name', this.firstNameInput.value)
  }

  onLastNameChange () {
    this.onTextChange('last_name', this.lastNameInput.value)
  }

  onStreetAddressChange () {
    this.onTextChange('streetAddress', this.streetAddressInput.value)
  }

  onZipChange () {
    this.onTextChange('zip', this.zipInput.value)
  }

  onCityChange () {
    this.onTextChange('city', this.cityInput.value)
  }

  onPhoneChange () {
    this.onTextChange('phone', this.phoneInput.value)
  }

  onTrustedSpotterNumberChange () {
    this.onTextChange('trusted_spotter_number', this.trustedSpotterNumberInput.value)
  }

  onSchoolNameChange () {
    this.onTextChange('school_name', this.schoolNameInput.value)
  }

  onClassNameChange () {
    this.onTextChange('class_name', this.classNameInput.value)
  }

  onContactEmailChange () {
    this.onTextChange('contact_email', this.contactEmailInput.value)
  }

  onContestChange () {
    this.profile.contest = this.contestInput.checked
  }

  async onSave (e) {
    e.preventDefault()

    const { t } = this.props

    if (!isOnline()) {
      this.setState({
        errorMessageForm: t('HELPERS.NETWORK_ERROR')
      })

      return
    }

    const { timeouts } = config

    try {
      this.resetMessages()

      this.setState({
        saving: true,
        saveButtonEnabled: true
      })

      const { authService } = this.props

      const result = await withTimeout(
        timeouts.default,
        new TimeoutError('timeout while signing up'),
        authService.updateProfile(this.profile)
      )
      if (result && result.profile) {
        const { profile } = result
        this.profile = profile
        this.setInputs()
        this.setState({
          successMessageForm: t('SCREENS.EDIT_PROFILE.SAVE_SUCCCESS')
        })
      } else {
        const errorMessages = errorMessagesFromApiErrors(result.errors, 'errorMessage')
        this.setState(errorMessages)
      }
    } catch (e) {
      let message

      if (e instanceof TimeoutError) {
        message = t('HELPERS.NETWORK_ERROR')
      }

      this.setState({
        errorMessageForm: message || t('HELPERS.UNEXPECTED_ERROR')
      })
    } finally {
      this.setState({
        saving: false,
        saveButtonEnabled: true
      })
    }
  }

  render () {
    const { t } = this.props
    const { saveButtonEnabled, saving } = this.state
    const submitButtonClass = saving ? 'is-loading' : ''

    return (
      <div className="signup-view">
        <div className="container mx-auto px-4 pt-28 md:pt-32 pb-12">
          <form onSubmit={this.onSave} className="flex flex-col space-y-8">
            { this.renderContest() }

            <div>
              <label className="label">{t('HELPERS.USER_TYPE')}</label>
              <select className="input w-full" ref={(ref) => this.userTypeSelect = ref} onChange={this.onUserTypeChange} >
                <option value="private">{t('HELPERS.USER_TYPE_PRIVATE')}</option>
                <option value="student">{t('HELPERS.USER_TYPE_STUDENT')}</option>
                <option value="trusted">{t('HELPERS.USER_TYPE_TRUSTED_SPOTTER')}</option>
              </select>
              { this.state.errorMessageUser_type.length > 0 && <p className="text-red mt-4">{this.state.errorMessageUser_type}</p> }
            </div>

            { this.renderSpecificFields() }

            <div>
              { this.state.successMessageForm.length > 0 && <p className="text-green mt-4">{this.state.successMessageForm}</p> }
              { this.state.errorMessageForm.length > 0 && <p className="text-red mt-4">{this.state.errorMessageForm}</p> }
            </div>

            <div>
              <button type="submit" className={`btn-primary ${submitButtonClass}`} disabled={!saveButtonEnabled}>{t('HELPERS.SAVE')}</button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  renderSpecificFields () {
    if (this.state.userType === 'trusted') {
      return this.renderFieldsTrusted();
    } else if (this.state.userType === 'student') {
      return this.renderFieldsStudent();
    }

    return this.renderFieldsPrivate()
  }

  renderFieldsPrivate() {
    return (
      <div className="flex flex-col space-y-8">
        { this.renderName() }
        { this.renderStreetAddress() }
        { this.renderZipAndCity () }
        { this.renderPhone () }
      </div>
    )
  }

  renderFieldsStudent() {
    const { t } = this.props
    return (
      <div className="flex flex-col space-y-8">
        { this.renderClassName() }

        <h2 className="text-xl sm:text-3xl text-gray font-bold">{ t('SCREENS.SIGNUP.SCHOOL_NAME_AND_ADDRESS') }</h2>
        { this.renderSchoolName() }
        { this.renderStreetAddress() }
        { this.renderZipAndCity () }

        <h2 className="text-xl sm:text-3xl text-gray font-bold">{ t('SCREENS.SIGNUP.CONTACT_PERSON') }</h2>
        { this.renderName() }
        { this.renderPhone () }
        { this.renderContactEmail () }

      </div>
    )
  }

  renderFieldsTrusted() {
    return (
      <div className="flex flex-col space-y-8">
        <div>
          { this.renderTrustedSpotterNumber () }
        </div>
        <div>
          { this.renderName() }
        </div>
        <div>
          { this.renderStreetAddress() }
        </div>
        <div>
          { this.renderZipAndCity () }
        </div>
        <div>
          { this.renderPhone () }
        </div>
      </div>
    )
  }

  renderSalutation () {
    const { t } = this.props

    return (
      <div className="field">
        <label className="label">{t('HELPERS.SALUTATION')}</label>
        <select className="input w-full" ref={(ref) => this.salutationSelect = ref} onChange={this.onSalutationChange} >
          <option value="Frau">{t('HELPERS.SALUTATION_MRS')}</option>
          <option value="Herr ">{t('HELPERS.SALUTATION_MR')}</option>
        </select>
        { this.state.errorMessageSalutation.length > 0 && <p className="text-red mt-4">{this.state.errorMessageSalutation}</p> }
      </div>
    )
  }

  renderFirstName () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.FIRST_NAME')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.firstNameInput = ref} onChange={this.onFirstNameChange} />
        </div>
        { this.state.errorMessageFirst_name.length > 0 && <p className="text-red mt-4">{this.state.errorMessageFirst_name}</p> }
      </div>
    )
  }

  renderLastName () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.LAST_NAME')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.lastNameInput = ref} onChange={this.onLastNameChange} />
        </div>
        { this.state.errorMessageLast_name.length > 0 && <p className="text-red mt-4">{this.state.errorMessageLast_name}</p> }
      </div>
    )
  }

  renderName () {
    return (
      <div className="flex flex-col space-y-8">
        { this.renderSalutation () }
        { this.renderFirstName () }
        { this.renderLastName () }
      </div>
    )
  }

  renderStreetAddress () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.STREET_ADDRESS')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.streetAddressInput = ref} onChange={this.onStreetAddressChange} />
        </div>
        { this.state.errorMessageStreetAddress.length > 0 && <p className="text-red mt-4">{this.state.errorMessageStreetAddress}</p> }
      </div>
    )
  }

  renderZip () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.ZIP')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.zipInput = ref} onChange={this.onZipChange} />
        </div>
        { this.state.errorMessageZip.length > 0 && <p className="text-red mt-4">{this.state.errorMessageZip}</p> }
      </div>
    )
  }

  renderCity () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.CITY')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.cityInput = ref} onChange={this.onCityChange} />
        </div>
        { this.state.errorMessageCity.length > 0 && <p className="text-red mt-4">{this.state.errorMessageCity}</p> }
      </div>
    )
  }

  renderZipAndCity () {
    return (
      <div className="flex flex-col space-y-8">
        { this.renderZip () }
        { this.renderCity () }
      </div>
    )
  }

  renderPhone () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.PHONE')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.phoneInput = ref} onChange={this.onPhoneChange} />
        </div>
        { this.state.errorMessagePhone.length > 0 && <p className="text-red mt-4">{this.state.errorMessagePhone}</p> }
      </div>
    )
  }

  renderContest () {
    const { t } = this.props

    return (
      <div style={{ display: 'none'}}>
        <div className="mb-2"><img src={csaLogo} alt="Citizen Science Award"/></div>
        <div className="field">
          <label className="checkbox">
            <input className="mr-5" type="checkbox" ref={(ref) => this.contestInput = ref} onChange={this.onContestChange} />
            <span> { t('SCREENS.SIGNUP.CONTEST') }</span>
          </label>
          { this.state.errorMessageContest.length > 0 && <p className="text-red mt-4">{this.state.errorMessageContest}</p> }
        </div>
      </div>
    )
  }

  renderTrustedSpotterNumber () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('SCREENS.SIGNUP.TRUSTED_SPOTTER_NUMBER')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.trustedSpotterNumberInput = ref} onChange={this.onTrustedSpotterNumberChange} />
        </div>
        { this.state.errorMessageTrusted_spotter_number.length > 0 && <p className="text-red mt-4">{this.state.errorMessageTrusted_spotter_number}</p> }
      </div>
    )
  }

  renderContactEmail () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.EMAIL')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.contactEmailInput = ref} onChange={this.onContactEmailChange} />
        </div>
        { this.state.errorMessageContact_email.length > 0 && <p className="text-red mt-4">{this.state.errorMessageContact_email}</p> }
      </div>
    )
  }

  renderSchoolName () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('SCREENS.SIGNUP.SCHOOL_NAME')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.schoolNameInput = ref} onChange={this.onSchoolNameChange} />
        </div>
        { this.state.errorMessageSchool_name.length > 0 && <p className="text-red mt-4">{this.state.errorMessageSchool_name}</p> }
      </div>
    )
  }

  renderClassName () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('SCREENS.SIGNUP.CLASS_NAME')}</label>
        <div>
          <input className="input w-full" type="text" ref={(ref) => this.classNameInput = ref} onChange={this.onClassNameChange} />
        </div>
        { this.state.errorMessageClass_name.length > 0 && <p className="text-red mt-4">{this.state.errorMessageClass_name}</p> }
      </div>
    )
  }

  componentDidMount () {
    window.IconicJS().inject('.signup-view img.iconic')

    const { authService } = this.props

    authService.fetchProfile().then(response => {
        const { profile } = response
        this.profile = profile
        this.setState({ userType: profile.user_type }, () => {
          this.setInputs()
        })
    })
  }

  setInputs () {
    if (this.userTypeSelect)  { this.userTypeSelect.value = this.profile.user_type }
    if (this.salutationSelect) { this.salutationSelect.value = this.profile.salutation }
    if (this.firstNameInput) { this.firstNameInput.value = this.profile.first_name }
    if (this.lastNameInput) { this.lastNameInput.value = this.profile.last_name }
    if (this.streetAddressInput) { this.streetAddressInput.value = this.profile.streetAddress }
    if (this.zipInput) { this.zipInput.value = this.profile.zip }
    if (this.cityInput) { this.cityInput.value = this.profile.city }
    if (this.phoneInput) { this.phoneInput.value = this.profile.phone }
    if (this.contestInput) { this.contestInput.checked = !!this.profile.contest }
    if (this.trustedSpotterNumberInput) { this.trustedSpotterNumberInput.value = this.profile.trusted_spotter_number }
    if (this.contactEmailInput) { this.contactEmailInput.value = this.profile.contact_email }
    if (this.classNameInput) { this.classNameInput.value = this.profile.class_name }
    if (this.schoolNameInput) { this.schoolNameInput.value = this.profile.school_name }
  }

  resetMessages () {
    this.setState({
        successMessageForm: '',
        errorMessageForm: '',
        errorMessageContest: '',
        errorMessageUser_type: '',
        errorMessageSalutation: '',
        errorMessageFirst_name: '',
        errorMessageLast_name: '',
        errorMessageStreetAddress: '',
        errorMessageZip: '',
        errorMessageCity: '',
        errorMessagePhone: '',
        errorMessageTrusted_spotter_number: '',
        errorMessageSchool_name: '',
        errorMessageClass_name: '',
        errorMessageContact_email: ''
    })
  }
}

export default EditProfileView
