import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTimeout, TimeoutError, isOnline } from '../lib/utils/network'
import config from '../config/config'
import { errorMessagesFromApiErrors } from '../lib/utils/string'
import envelopeIcon from '../assets/iconic/envelope.svg'
import personIcon from '../assets/iconic/person.svg'
import lockIcon from '../assets/iconic/lock.svg'
import eyeIcon from '../assets/iconic/eye.svg'
import csaLogo from '../assets/csa-logo24.gif'

class SignupView extends Component {
  constructor (props) {
    super(props)

    this.user = {
      email: null,
      username: null,
      password: null,
      user_type: 'private',
      salutation: 'Frau'
    }

    this.state = {
      userType: 'private',
      signupBtnEnabled: false,
      signingUp: false,
      errorMessageForm: '',
      errorMessageEmail: '',
      errorMessageUsername: '',
      errorMessagePassword: '',
      errorMessageContest: '',
      errorMessageUser_type: '',
      errorMessageSalutation: '',
      errorMessageFirst_name: '',
      errorMessageLast_name: '',
      errorMessageStreetAddress: '',
      errorMessageZip: '',
      errorMessageCity: '',
      errorMessagePhone: '',
      errorMessageTrusted_spotter_number: '',
      errorMessageSchool_name: '',
      errorMessageClass_name: '',
      errorMessageContact_email: '',
      showPassword: false
    }

    this.onSignup = this.onSignup.bind(this)
    this.toggleShowPassword = this.toggleShowPassword.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onUsernameChange = this.onUsernameChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onUserTypeChange = this.onUserTypeChange.bind(this)
    this.onSalutationChange = this.onSalutationChange.bind(this)
    this.onFirstNameChange = this.onFirstNameChange.bind(this)
    this.onLastNameChange = this.onLastNameChange.bind(this)
    this.onStreetAddressChange = this.onStreetAddressChange.bind(this)
    this.onZipChange = this.onZipChange.bind(this)
    this.onCityChange = this.onCityChange.bind(this)
    this.onPhoneChange = this.onPhoneChange.bind(this)
    this.onTrustedSpotterNumberChange = this.onTrustedSpotterNumberChange.bind(this)
    this.onSchoolNameChange = this.onSchoolNameChange.bind(this)
    this.onClassNameChange = this.onClassNameChange.bind(this)
    this.onContactEmailChange = this.onContactEmailChange.bind(this)
    this.onContestChange = this.onContestChange.bind(this)
  }

  onTextChange (key, text) {
    this.user[key] = text

    if (this.user.email && this.user.username && this.user.password && !this.state.signupBtnEnabled) {
      this.setState({ signupBtnEnabled: true })
    }

    if ((!this.user.username || !this.user.password) && this.state.signupBtnEnabled) {
      this.setState({ signupBtnEnabled: false })
    }

    if (key === 'user_type') {
      this.setState({ userType: text })
      if (text !== 'trusted') {
        this.user.trusted_spotter_number = ''
      }
    }
  }

  onEmailChange () {
    this.onTextChange('email', this.emailInput.value)
  }

  onUsernameChange () {
    this.onTextChange('username', this.usernameInput.value)
  }

  onPasswordChange () {
    this.onTextChange('password', this.passwordInput.value)
  }

  onUserTypeChange () {
    this.onTextChange('user_type', this.userTypeSelect.value)
  }

  onSalutationChange () {
    this.onTextChange('salutation', this.salutationSelect.value)
  }

  onFirstNameChange () {
    this.onTextChange('first_name', this.firstNameInput.value)
  }

  onLastNameChange () {
    this.onTextChange('last_name', this.lastNameInput.value)
  }

  onStreetAddressChange () {
    this.onTextChange('streetAddress', this.streetAddressInput.value)
  }

  onZipChange () {
    this.onTextChange('zip', this.zipInput.value)
  }

  onCityChange () {
    this.onTextChange('city', this.cityInput.value)
  }

  onPhoneChange () {
    this.onTextChange('phone', this.phoneInput.value)
  }

  onTrustedSpotterNumberChange () {
    this.onTextChange('trusted_spotter_number', this.trustedSpotterNumberInput.value)
  }

  onSchoolNameChange () {
    this.onTextChange('school_name', this.schoolNameInput.value)
  }

  onClassNameChange () {
    this.onTextChange('class_name', this.classNameInput.value)
  }

  onContactEmailChange () {
    this.onTextChange('contact_email', this.contactEmailInput.value)
  }

  onContestChange () {
    this.user.contest = this.contestInput.checked
  }

  async onSignup (e) {
    e.preventDefault()

    const { t } = this.props

    if (!isOnline()) {
      this.setState({
        errorMessageForm: t('HELPERS.NETWORK_ERROR')
      })

      return
    }

    const { timeouts } = config

    try {
      this.resetErrors()

      this.setState({
        signingUp: true,
        signupBtnEnabled: false
      })

      const result = await withTimeout(
        timeouts.default,
        new TimeoutError('timeout while signing up'),
        this.props.authService.register(this.user)
      )
      if (result && result.success) {
        if (this.props.onSignup) {
          this.props.onSignup()
        }
      } else {
        const errorMessages = errorMessagesFromApiErrors(result.errors, 'errorMessage')
        this.setState(errorMessages)
      }
    } catch (e) {
      let message

      if (e instanceof TimeoutError) {
        message = t('HELPERS.NETWORK_ERROR')
      }

      this.setState({
        errorMessageForm: message || t('HELPERS.UNEXPECTED_ERROR')
      })
    } finally {
      this.setState({
        signingUp: false,
        signupBtnEnabled: true
      })
    }
  }

  render () {
    const { idRoot } = config
    const { t } = this.props
    const { showPassword, signupBtnEnabled, signingUp } = this.state
    const passwordInputType = showPassword ? 'text' : 'password'
    const eyeOpenClass = showPassword ? 'hidden' : ''
    const eyeClosedClass = showPassword ? '' : 'hidden'
    const submitButtonClass = signingUp ? 'is-loading' : ''

    return (
      <div className="signup-view">
        <div className="container mx-auto px-4 pt-28 md:pt-32 pb-12">
          <form onSubmit={this.onSignup} className="flex flex-col space-y-8">
            <div>
              <label className="label">{t('HELPERS.EMAIL')}</label>
              <div className="flex w-full input">
                <span className="input-icon">
                  <img data-src={envelopeIcon} className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.EMAIL')} aria-hidden="true" />
                </span>
                <input className="flex-auto outline-none pl-4" type="text" ref={(ref) => this.emailInput = ref} onChange={this.onEmailChange} />
              </div>
              { this.state.errorMessageEmail.length > 0 && <p className="text-red mt-4">{this.state.errorMessageEmail}</p> }
            </div>

            <div>
              <label className="label">{t('HELPERS.USERNAME')}</label>
              <div className="flex w-full input">
                <span className="input-icon">
                  <img data-src={personIcon} className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.USERNAME')} aria-hidden="true" />
                </span>
                <input className="flex-auto outline-none pl-4" type="text" ref={(ref) => this.usernameInput = ref} onChange={this.onUsernameChange} />
              </div>
              { this.state.errorMessageUsername.length > 0 && <p className="text-red mt-4">{this.state.errorMessageUsername}</p> }
            </div>

            <div>
              <label className="label">{t('HELPERS.PASSWORD')}</label>
              <div className="flex w-full input">
                <span className="input-icon">
                   <img data-src={lockIcon} className="iconic iconic-sm iconic--dampened" alt="Kommentar" />
                </span>
                <input className="flex-auto outline-none pl-4" type={passwordInputType} ref={(ref) => this.passwordInput = ref} onChange={this.onPasswordChange} />
                <span className="input-icon mt-1.5" style={{pointerEvents: 'initial'}}>
                  <button type="button" onClick={this.toggleShowPassword}>
                    <span className={eyeOpenClass}><img data-src={eyeIcon} data-state="open" className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.SHOW')} aria-hidden="true" /></span>
                    <span className={eyeClosedClass}><img data-src={eyeIcon} data-state="closed" className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.HIDE')} aria-hidden="true" /></span>
                  </button>
                </span>
              </div>
              { this.state.errorMessagePassword.length > 0 && <p className="text-red mt-4">{this.state.errorMessagePassword}</p> }
            </div>

            {/* <hr className="text-gray-lighter" /> */}

            { this.renderContest() }

            <div>
              <label className="label">{t('HELPERS.USER_TYPE')}</label>
              <select className="input w-full" ref={(ref) => this.userTypeSelect = ref} onChange={this.onUserTypeChange} >
                <option value="private">{t('HELPERS.USER_TYPE_PRIVATE')}</option>
                <option value="student">{t('HELPERS.USER_TYPE_STUDENT')}</option>
                <option value="trusted">{t('HELPERS.USER_TYPE_TRUSTED_SPOTTER')}</option>
              </select>
              { this.state.errorMessageUser_type.length > 0 && <p className="text-red mt-4">{this.state.errorMessageUser_type}</p> }
            </div>

            {/* <hr className="text-gray-lighter" /> */}

            { this.renderSpecificFields() }

            { this.state.errorMessageForm.length > 0 && <p className="text-red mt-4">{this.state.errorMessageForm}</p> }

            {/* <hr className="text-gray-lighter" /> */}

            <div>
              <button type="submit" className={`btn-primary ${submitButtonClass}`} disabled={!signupBtnEnabled}>{t('SCREENS.SIGNUP.SIGNUP_BUTTON')}</button>
            </div>

            <p>{t('SCREENS.SIGNUP.LOGIN_CTA')} <Link to="/login/" className="link">{t('SCREENS.LOGIN.TITLE')}</Link>.</p>

            <p>{t('SCREENS.LOGIN.RESEND_VERIFICATION.DESCRIPTION')} <a href={`${idRoot}/user/registration/resend`} target="_blank" className="link">{t('SCREENS.LOGIN.RESEND_VERIFICATION.SEND')}</a></p>
          </form>
        </div>
      </div>
    )
  }

  renderSpecificFields () {
    if (this.state.userType === 'trusted') {
      return this.renderFieldsTrusted();
    } else if (this.state.userType === 'student') {
      return this.renderFieldsStudent();
    }

    return this.renderFieldsPrivate()
  }

  renderFieldsPrivate() {
    return (
      <div className="flex flex-col space-y-8">
        { this.renderName() }
        { this.renderStreetAddress() }
        { this.renderZipAndCity () }
        { this.renderPhone () }
      </div>
    )
  }

  renderFieldsStudent() {
    const { t } = this.props
    return (
      <div className="flex flex-col space-y-8">
        { this.renderClassName() }

        <h2 className="text-xl sm:text-3xl text-gray font-bold">{ t('SCREENS.SIGNUP.SCHOOL_NAME_AND_ADDRESS') }</h2>
        { this.renderSchoolName() }
        { this.renderStreetAddress(true) }
        { this.renderZipAndCity (true) }

        <h2 className="text-xl sm:text-3xl text-gray font-bold">{ t('SCREENS.SIGNUP.CONTACT_PERSON') }</h2>
        { this.renderName(true) }
        { this.renderPhone (true) }
        { this.renderContactEmail () }

      </div>
    )
  }

  renderFieldsTrusted() {
    return (
      <div className="flex flex-col space-y-8">
        { this.renderTrustedSpotterNumber () }
        { this.renderName() }
        { this.renderStreetAddress() }
        { this.renderZipAndCity () }
        { this.renderPhone () }
      </div>
    )
  }

  renderSalutation (required = false) {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.SALUTATION')}</label>
        <select className="input w-full" required={required} ref={(ref) => this.salutationSelect = ref} onChange={this.onSalutationChange} >
          <option value="Frau">{t('HELPERS.SALUTATION_MRS')}</option>
          <option value="Herr ">{t('HELPERS.SALUTATION_MR')}</option>
        </select>
        { this.state.errorMessageSalutation.length > 0 && <p className="text-red mt-4">{this.state.errorMessageSalutation}</p> }
      </div>
    )
  }

  renderFirstName (required = false) {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.FIRST_NAME')}</label>
        <div>
          <input className="input w-full" type="text" required={required} ref={(ref) => this.firstNameInput = ref} onChange={this.onFirstNameChange} />
        </div>
        { this.state.errorMessageFirst_name.length > 0 && <p className="text-red mt-4">{this.state.errorMessageFirst_name}</p> }
      </div>
    )
  }

  renderLastName (required = false) {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.LAST_NAME')}</label>
        <div>
          <input className="input w-full" type="text" required={required} ref={(ref) => this.lastNameInput = ref} onChange={this.onLastNameChange} />
        </div>
        { this.state.errorMessageLast_name.length > 0 && <p className="text-red mt-4">{this.state.errorMessageLast_name}</p> }
      </div>
    )
  }

  renderName (required = false) {
    return (
      <div className="flex flex-col space-y-8">
        { this.renderSalutation (required) }
        { this.renderFirstName (required) }
        { this.renderLastName (required) }
      </div>
    )
  }

  renderStreetAddress (required = false) {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.STREET_ADDRESS')}</label>
        <div>
          <input className="input w-full" type="text" required={required} ref={(ref) => this.streetAddressInput = ref} onChange={this.onStreetAddressChange} />
        </div>
        { this.state.errorMessageStreetAddress.length > 0 && <p className="text-red mt-4">{this.state.errorMessageStreetAddress}</p> }
      </div>
    )
  }

  renderZip (required = false) {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.ZIP')}</label>
        <div>
          <input className="input w-full" type="text" required={required} ref={(ref) => this.zipInput = ref} onChange={this.onZipChange} />
        </div>
        { this.state.errorMessageZip.length > 0 && <p className="text-red mt-4">{this.state.errorMessageZip}</p> }
      </div>
    )
  }

  renderCity (required = false) {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.CITY')}</label>
        <div>
          <input className="input w-full" type="text" required={required} ref={(ref) => this.cityInput = ref} onChange={this.onCityChange} />
        </div>
        { this.state.errorMessageCity.length > 0 && <p className="text-red mt-4">{this.state.errorMessageCity}</p> }
      </div>
    )
  }

  renderZipAndCity (required = false) {
    return (
      <div className="flex flex-col space-y-8">
        { this.renderZip (required) }
        { this.renderCity (required) }
      </div>
    )
  }

  renderPhone (required = false) {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.PHONE')}</label>
        <div>
          <input className="input w-full" type="text" required={required} ref={(ref) => this.phoneInput = ref} onChange={this.onPhoneChange} />
        </div>
        { this.state.errorMessagePhone.length > 0 && <p className="text-red mt-4">{this.state.errorMessagePhone}</p> }
      </div>
    )
  }

  renderContest () {
    const { t } = this.props

    return (
      <div style={{ display: 'none'}}>
        <div className="mb-2"><img src={csaLogo} alt="Citizen Science Award"/></div>
        <div>
          <label className="checkbox">
            <input className="mr-5" type="checkbox" ref={(ref) => this.contestInput = ref} onChange={this.onContestChange} />
            <span> { t('SCREENS.SIGNUP.CONTEST') }</span>
          </label>
          { this.state.errorMessageContest.length > 0 && <p className="text-red mt-4">{this.state.errorMessageContest}</p> }
        </div>
      </div>
    )
  }

  renderTrustedSpotterNumber () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('SCREENS.SIGNUP.TRUSTED_SPOTTER_NUMBER')}</label>
        <div>
          <input className="input w-full" type="text" required ref={(ref) => this.trustedSpotterNumberInput = ref} onChange={this.onTrustedSpotterNumberChange} />
        </div>
        { this.state.errorMessageTrusted_spotter_number.length > 0 && <p className="text-red mt-4">{this.state.errorMessageTrusted_spotter_number}</p> }
      </div>
    )
  }

  renderContactEmail () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('HELPERS.EMAIL')}</label>
        <div>
          <input className="input w-full" type="text" required ref={(ref) => this.contactEmailInput = ref} onChange={this.onContactEmailChange} />
        </div>
        { this.state.errorMessageContact_email.length > 0 && <p className="text-red mt-4">{this.state.errorMessageContact_email}</p> }
      </div>
    )
  }

  renderSchoolName () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('SCREENS.SIGNUP.SCHOOL_NAME')}</label>
        <div>
          <input className="input w-full" type="text" required ref={(ref) => this.schoolNameInput = ref} onChange={this.onSchoolNameChange} />
        </div>
        { this.state.errorMessageSchool_name.length > 0 && <p className="text-red mt-4">{this.state.errorMessageSchool_name}</p> }
      </div>
    )
  }

  renderClassName () {
    const { t } = this.props

    return (
      <div>
        <label className="label">{t('SCREENS.SIGNUP.CLASS_NAME')}</label>
        <div>
          <input className="input w-full" type="text" required ref={(ref) => this.classNameInput = ref} onChange={this.onClassNameChange} />
        </div>
        { this.state.errorMessageClass_name.length > 0 && <p className="text-red mt-4">{this.state.errorMessageClass_name}</p> }
      </div>
    )
  }

  componentDidMount () {
    window.IconicJS().inject('.signup-view img.iconic')
  }

  resetErrors () {
    this.setState({
      errorMessageForm: '',
      errorMessageEmail: '',
      errorMessageUsername: '',
      errorMessagePassword: '',
      errorMessageContest: '',
      errorMessageUser_type: '',
      errorMessageSalutation: '',
      errorMessageFirst_name: '',
      errorMessageLast_name: '',
      errorMessageStreetAddress: '',
      errorMessageZip: '',
      errorMessageCity: '',
      errorMessagePhone: '',
      errorMessageTrusted_spotter_number: '',
      errorMessageSchool_name: '',
      errorMessageClass_name: '',
      errorMessageContact_email: '',
    })
  }

  toggleShowPassword () {
    const { showPassword } = this.state
    this.setState({ showPassword: !showPassword })
  }
}

export default SignupView
